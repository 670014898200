import { media } from '@cheese-fondue/helpers';
import { COLORS, SPACING } from '@cheese-fondue/styles/theme-constants';
import { typography } from '@cheese-fondue/styles/typography';
import styled from 'styled-components';
import { responsiveMedia } from '../../helpers/media-queries';
import { OneColumnTextPropsType } from './oneColumnText-props';

type SectionGridProps = Pick<OneColumnTextPropsType, 'pb' | 'pt'> & { color?: string };

export const Wrapper = styled.div<SectionGridProps>`
  padding-left: ${SPACING.one}px;
  padding-right: ${SPACING.one}px;
  color: ${({ color }) => color ?? COLORS.black};

  ${media.medium`
    padding-left: ${SPACING.two}px;
    padding-right: ${SPACING.two}px;
  `}

  ${({ pb, pt }) =>
    responsiveMedia.map((media, i) => {
      return media`
        padding-top: ${pt && pt[i] ? pt[i] : SPACING.one}px;
        padding-bottom: ${pb && pb[i] ? pb[i] : SPACING.one}px;
     `;
    })}
`;

export const Body = styled.div<Partial<OneColumnTextPropsType> & { hasSubtitle?: boolean }>`
  margin-top: ${({ hasSubtitle }) => (hasSubtitle ? SPACING.four : SPACING.two)}px;

  ${media.medium`
    width: ${({ alignment }): string => (alignment === 'Left' ? `60` : `100`)}%;
  `} > p {
    ${typography.p}

    ${media.medium`
        ${typography.pMedium}
    `}
  }
`;
