// import Head from '@components/head';
// import Header from '@components/header';
// import MobileMenu from '@components/mobile_menu';
// import MobileMenuContext from '@contexts/mobile_menu_context';
import { Footer, Header } from '@cheese-fondue/components';
import GlobalStyle from '@cheese-fondue/styles';
import { THEME } from '@cheese-fondue/styles/theme';
import Head from '@components/head';
import { WindowLocation } from '@reach/router';
import '@static/styles/cheese_font_styles.css';
import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';

interface LayoutProps {
  pageName: string;
  children: React.ReactNode;
  location: WindowLocation;
  queryData?: object;
  whiteOnBlueNav?: boolean;
  blueOnOrange?: boolean;
  constrainedWidth?: boolean;
}

const Layout = ({
  pageName,
  children,
  location,
  queryData,
  constrainedWidth,
  whiteOnBlueNav = false,
  blueOnOrange = false,
}: LayoutProps): ReactElement => {
  return (
    <ThemeProvider theme={THEME}>
      <>
        <GlobalStyle />
        <Head pageName={pageName} location={location} pageContext={queryData} />
        <Header location={location} whiteOnBlue={whiteOnBlueNav} blueOnOrange={blueOnOrange} />
        <main id="main-content" {...(constrainedWidth ? { className: 'constrained' } : {})}>
          {children}
        </main>
        <div id="modal-root"></div>
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;
