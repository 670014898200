import { Box, Grid } from '@cheese-fondue/components/grid';
import Layout from '@cheese-fondue/layout';
import { PageProps } from 'gatsby';
import React, { ReactElement } from 'react';
import { Text } from '@cheese-fondue/components/atoms/text';
import { ComponentWrapper } from '@cheese-fondue/components/componentWrapper/componentWrapper';
import { COLORS } from '@cheese-fondue/styles';
import { OneColumnText } from '@cheese-fondue/components/oneColumnText';

const MissionPage = (props: PageProps): ReactElement => {
  const body = [
    'Delphia was founded on the belief that if a machine acquires intelligence by training on data created by people, then those people should be rewarded commensurate with their contribution to the model. We believe this contribution can and should be measured, valued, and incentivized in order to produce artificial intelligence of the highest and most aligned quality.',
    'We envision a world where our data plays an important role in achieving financial prosperity.',
  ];

  return (
    <Layout location={props.location} pageName="Mission">
      <Grid>
        <Box cols={[12]} className="mt-four mt-medium-six">
          <ComponentWrapper backgroundColorLeft={COLORS.white} backgroundColorRight={COLORS.white}>
            <OneColumnText alignment="Left" strapline={''} title={''}>
              <div>
                <Text as="h1" size="h1" mb={['one', 'two']}>
                  Mission
                </Text>
                {body.map(bodyPoint => {
                  return (
                    <Text as="p" size={['p', 'pMedium']} key={bodyPoint} mt="one" mb="two">
                      {bodyPoint}
                    </Text>
                  );
                })}
              </div>
            </OneColumnText>
          </ComponentWrapper>
        </Box>
      </Grid>
    </Layout>
  );
};

export default MissionPage;
