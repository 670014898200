import { Box, Grid } from '@cheese-fondue/components/grid';
import React, { ReactElement } from 'react';
import { responsiveProp } from '../../helpers/responsiveProp';
import { TypographyVariants } from '../../styles/typography';
import { Text } from '../atoms/text';
import { getMargin } from '../atoms/text/text';
import { OneColumnTextPropsType } from './oneColumnText-props';
import { Body, Wrapper } from './oneColumnText.styles';

type HTMLTagNames = keyof HTMLElementTagNameMap;
type TypographySizeType = TypographyVariants | TypographyVariants[];

export const OneColumnText = ({
  children,
  strapline,
  title,
  subtitle,
  large,
  pt = ['one', 'two'],
  pr = ['one', 'two'],
  pb = ['one', 'two'],
  pl = ['one', 'two'],
  alignment = 'Center',
  tag = 'section',
  ...props
}: OneColumnTextPropsType): ReactElement => {
  const hasSubtitle: boolean = typeof subtitle !== 'undefined' && subtitle !== '';
  const responsivePB = responsiveProp({ prop: pb, mapper: val => getMargin(val) });
  const responsivePT = responsiveProp({ prop: pt, mapper: val => getMargin(val) });
  const titleSizes: TypographySizeType = large ? ['h1', 'h1XXXLarge', 'h1XXXXLarge'] : ['h1', 'h1XXLarge'];
  const straplineSizes: TypographySizeType = large ? ['h5', 'h3'] : ['preSmall'];
  const subtitleSizes: TypographySizeType = large ? ['p', 'pMedium'] : ['pMedium'];
  const isCentered = alignment === 'Center';
  return (
    <Wrapper {...props} pb={responsivePB} pt={responsivePT}>
      <Grid tag={tag}>
        <Box cols={[0, isCentered ? 3 : 0]} />
        <Box cols={[12, isCentered ? 6 : 12]}>
          <Text as="h3" size={straplineSizes}>
            {strapline}
          </Text>
          <Text as="h2" size={titleSizes} mt={['one']} mb={['one', 'two']}>
            {title}
          </Text>
          <Text as="p" size={subtitleSizes}>
            {subtitle}
          </Text>
          <Body className="body" hasSubtitle={hasSubtitle} alignment={alignment}>
            {children}
          </Body>
        </Box>
      </Grid>
    </Wrapper>
  );
};
